import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const  routes = [
  {
    path: "/",
    component: () => import("../views/index.vue")
  }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {  
  // 设置页面标题  
  document.title = 'FATBOY';  
  next();  
});  
export default router

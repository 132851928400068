export default {
    // 这是导航
    setMenubar(state, payload) {
        state.menubar = payload
    },
    // 记录题库
    setQuestionList(state, payload) {
        state.questionList = payload
    },
    // 记录所有数据
    setAlldataObj(state, payload) {
        state.alldata = payload
    },
    // 记录登录信息
    setLoginDate(state, payload) {
        state.loginDate = payload
    },
    // 记录科目
    setSubjectListitem(state, payload) {
        state.subjectListitem = payload
    },
    // 记录答题时间
    setDatishijian(state, payload) {
        state.datishijian = payload
    }

}

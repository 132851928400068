<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view />
  </div>
</template>

<script>
import './assets/css/public.css'  //引入公用css
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: 'pikafont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f7fb;
  position: relative;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
nav {
  padding: 30px;
}
p{
  margin: 0;
  padding: 0;
}
</style>
